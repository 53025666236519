import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Main } from 'layouts';
import Container  from 'components/Container';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
// import Video1 from 'media/virtual-tour.mp4'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const Reviews = () => {
  const theme = useTheme();
  return (
    <Main>
      <Container maxWidth={0.9} paddingTop={'0 !important'}>


    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          width: 1,
          height: 1,
          zIndex: 3,
          background: theme.palette.primary.main,
          opacity: 0.2,
        },
      }}
    >
      <Box
        component={'video'}
        width={1}
        autoPlay={true}
        muted={false}
        loop={true}
      >
        <source
          src="https://www.npsypr.edu.in/static-assets/videos/virtual-tour.mp4"
          type="video/mp4"
        />
        {/* <source
          src="https://nps-ypr-videos.s3.ap-south-1.amazonaws.com/Virtual+Tour+(1).mp4"
          type="video/webm"
        />
        <source
          src="https://nps-ypr-videos.s3.ap-south-1.amazonaws.com/Virtual+Tour+(1).mp4"
          type="video/ogg"
        /> */}
        Your browser do not support HTML5 video.
      </Box>
    </Box>
    </Container>
    </Main>
  );
};

export default Reviews;
